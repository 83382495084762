/* Animación de entrada */
.fade-in {
    opacity: 0;
    animation: fadeIn 0s forwards;
  }
  
  @keyframes fadeIn {
    to {
      opacity: 1;
    }
  }
  
  /* Animación de salida que sube completamente sin desaparecer */
  .slide-up {
    animation: slideUp 1s forwards;
  }
  
  @keyframes slideUp {
    0% {
      transform: translateY(0);
      opacity: 1;
    }
    100% {
      transform: translateY(-100vh); /* Mover completamente fuera de la pantalla */
      opacity: 1; /* Mantener la opacidad para no desvanecer */
    }
  }
  
  /* Estilo del SplashScreen */
  .splash-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(17, 24, 39, 0.9); /* Fondo gris oscuro con opacidad */
    z-index: 1000; /* Asegura que esté sobre el Dashboard */
  }
  
  /* Círculo de carga */
  .loader {
    border: 4px solid rgba(255, 255, 255, 0.2);
    border-top: 4px solid #ffffff;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  

  /* Animación de parpadeo */
@keyframes blink {
    0%, 50%, 100% {
      opacity: 1;
    }
    25%, 75% {
      opacity: 0.4;
    }
  }
  
  /* Clase de animación */
  .animate-blink {
    animation: blink 4s infinite;
  }
  