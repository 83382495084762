/* Animación de entrada desde abajo hacia arriba */
.slide-in-bottom {
    animation: slideFromBottom 0.5s ease-out forwards;
  }
  
  @keyframes slideFromBottom {
    from {
      transform: translateY(100vh);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  /* Animación de salida hacia arriba */
  .slide-out-top {
    animation: slideToTop 1.5s ease-in forwards;
  }
  
  @keyframes slideToTop {
    from {
      transform: translateY(0);
      opacity: 1;
    }
    to {
      transform: translateY(-100vh); /* Mover hacia arriba fuera de la pantalla */
      opacity: 0;
    }
  }
  
  /* Estilo de la pantalla de carga */
  .loading-container {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgb(17, 24, 39); /* Fondo oscuro */
    z-index: 1000; /* Asegura que esté sobre el Dashboard */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  