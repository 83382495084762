.scroll-x {
    max-width: 100%;
    overflow-x: auto;
    overscroll-behavior-x: contain;
    -webkit-overflow-scrolling: touch; /* Para un desplazamiento suave en iOS */
  }
  
  .wrapper {
    display: grid;
    scroll-snap-type: x mandatory;
    padding: 2rem 0;
  }
  
  .items {
    display: flex;
    gap: 1rem;
    scroll-snap-align: start;
  }
  
  .items > * {
    flex-shrink: 0;
    width: 300px; /* Ancho de las tarjetas (corregido) */
    height: 400px; /* Altura de las tarjetas */
    border-radius: 0.9rem;
  }
  